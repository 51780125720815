(function () {
    angular.module('informaApp')
        .service('CvsHelper', CvsHelper);

    function CvsHelper() {
        return {
            generateAndSave(fileName, source) {
                const cvsData = getCvsData(source);

                return saveCvs(cvsData, fileName);
            }
        };
    }

    function saveCvs(cvsData, fileName) {
        return new Promise((resolve) => {
            try {
                const blob = new Blob([cvsData], {type: 'text/csv;charset=utf-8'});
                const fileSaver = saveAs(blob, fileName);

                fileSaver.onwriteend = () => resolve();
                fileSaver.onerror = () => resolve(saveCvsAsTextFile(cvsData, fileName));
            } catch {
                resolve(saveCvsAsTextFile(cvsData, fileName));
            }
        });
    }

    function saveCvsAsTextFile(cvsData, fileName) {
        return saveTextAs(encodeURI(cvsData), fileName)
            ? Promise.resolve()
            : Promise.reject();
    }

    function getCvsData(source) {
        const result = source.map(mapLines);

        return [].concat(...result).join('\n');
    }

    function mapLines(linesConfig) {
        const lines = linesConfig.source.map(line => mapLineToCvs(line, linesConfig.getFields));

        return [
            ...lines,
            ...getEmptyLines(linesConfig.emptyLinesAfter || 0)
        ];
    }

    function getEmptyLines(emptyLinesAfter) {
        return new Array(emptyLinesAfter).fill('');
    }

    function mapLineToCvs(line, getFields) {
        if (!getFields) {
            return line;
        }

        return getFields
            .map(getValue => getValue(line))
            .join(',');
    }
})();
